import { Box, PageWrapper, Typography } from '@oresundsbron/bridge-ui';
import { cva, cx } from 'class-variance-authority';
import Image from 'next/image';
import { createContext, FC, useState } from 'react';
import { SEOMetadata } from '../components/SEOMetadata';
import { usePage } from '../hooks/usePage';
import { Breadcrumbs } from './Breadcrumbs';
import { ContentfulGrid } from './ContentfulGrid';
import VideoBanner from '~/components/VideoBanner';
import { pageGridMaxWidthWrapper } from './ContentfulGrid/Column';
import {
  BannerVisibilityProvider,
  useBannerVisibility,
} from '@oresundsbron/app-common';
import { MetadataItem } from '~/lib/metadata';

const bannerSection = cva(
  ['z-10', 'col-start-2', 'text-center', 'relative', 'flex', 'flex-col'],
  {
    variants: {
      banner: {
        true: [
          'pb-12',
          'sm:pb-18',
          'md:pb-24',
          'min-h-[35vh]',
          'sm:min-h-[40vh]',
          'md:min-h-[45vh]',
        ],
        false: ['pb-8', 'sm:pb-12', 'md:pb-12'],
      },
    },
  }
);

export const PageLayout = {
  Normal: 'Normal',
  Card: 'Card',
};

export const Page: FC<{ id: string }> = ({ id }) => {
  const { page, seoMetadata } = usePage({ id });

  if (!page) {
    return null;
  }

  return (
    <BannerVisibilityProvider>
      <PageContent page={page} seoMetadata={seoMetadata} />
    </BannerVisibilityProvider>
  );
};

const PageContent: FC<{ page: any; seoMetadata: MetadataItem[] }> = ({
  page,
  seoMetadata,
}) => {
  const { isBannerVisible } = useBannerVisibility();
  const { banner, sys, title, preamble, content, pageLayout, solidBackground } =
    page;
  const isVideoBanner = banner?.contentType === 'video/mp4';
  const isCardPageLayout = pageLayout === PageLayout.Card;

  return (
    <Box as="main">
      <SEOMetadata items={seoMetadata} customJsonLd={page?.jsonLd} />
      <PageWrapper as="div" className="relative">
        <Breadcrumbs
          id={sys?.id}
          invert={!!banner}
          className="z-10 col-start-2 pb-6 pt-6 sm:pb-12"
        />
        {isBannerVisible && (
          <Box className={bannerSection({ banner: !!banner })}>
            <Box className="my-auto">
              {!!solidBackground && (
                <div className="mt-4 inline-block rounded-md bg-purple-800 p-16 pt-12 text-left md:mt-8">
                  <Typography
                    color={banner ? 'white' : undefined}
                    as="h1"
                    intent="display"
                    size={banner ? 'lg' : 'md'}
                    className="text-left"
                  >
                    {title}
                  </Typography>
                  <Typography
                    color={banner ? 'white' : undefined}
                    size="lg"
                    className="mx-auto max-w-[70ch] pt-6 text-left sm:pt-8"
                  >
                    {preamble}
                  </Typography>
                </div>
              )}
              {!isCardPageLayout && (
                <Typography
                  color={banner ? 'white' : undefined}
                  as="h1"
                  intent="display"
                  size={banner ? 'lg' : 'md'}
                  className="text-center"
                >
                  {title}
                </Typography>
              )}
              {preamble && !isCardPageLayout && (
                <Typography
                  color={banner ? 'white' : undefined}
                  size="lg"
                  className="mx-auto max-w-[70ch] pt-6 text-center sm:pt-8"
                >
                  {preamble}
                </Typography>
              )}
            </Box>
          </Box>
        )}
        {isBannerVisible && banner && (
          <Box className="absolute inset-0">
            {isVideoBanner ? (
              <VideoBanner url={banner.url} />
            ) : (
              <Image
                src={banner.url}
                alt={banner.description || ''}
                priority
                fill
                sizes="(max-width: 1759px) 100vw, 1760px"
                style={{
                  aspectRatio: '16/9',
                  objectFit: 'cover',
                }}
              />
            )}
            <Box
              className={`absolute inset-0 mx-auto ${
                isVideoBanner ? pageGridMaxWidthWrapper : ''
              }`}
            />
          </Box>
        )}
      </PageWrapper>

      <div
        className={`${
          isCardPageLayout
            ? 'min-h-[50vh] md:relative md:mx-auto md:max-w-7xl md:rounded-t-xl md:bg-white md:px-16 md:shadow-xl'
            : ''
        } ${isBannerVisible && isCardPageLayout ? 'md:-mt-16' : ''}`}
      >
        {isCardPageLayout && !isBannerVisible && !solidBackground && (
          <Typography
            as="h1"
            intent="display"
            className="px-4 pt-4 sm:px-8 sm:pt-8 md:pt-16"
          >
            {title}
          </Typography>
        )}
        <ContentfulGrid rows={content} />
      </div>
    </Box>
  );
};
